

.rbt-menu {
    border: 10px;
    float: left;
}
.dropdown-menu {
    background-color: white;
}
.dropdown-menu a {
    padding-top: 10px;
    padding-left: 10px;
    display: block;
    /* border-bottom: 2px solid ; */
}

.dropdown-menu a:hover {
    border: 1px solid ;
    border-radius: 4px;
}
