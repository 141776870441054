@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: DejaVuSerif;
    src: url('./fonts/DejaVuSerif.ttf');
}

.braille {
    font-family: DejaVuSerif;
    /* font-family: DejaVuSerif;
    src: local(''), url(./fonts/DejaVuSerif.ttf) format('ttf'); */
}

#help-video video::-webkit-media-controls-timeline {
    display: none;
}
#help-video video:fullscreen::-webkit-media-controls-timeline  {
    display: block;        
}
